const syncFields = (elem) => {
  const parent = elem.closest('tr');
  const fields = parent.find('input[name="reason"]');
  fields.val(elem.val());
}

let textFields = $('.reason');
textFields.each(function () {
  let defaultVal = $(this).val(),
    cutVal = $.trim(defaultVal);
  $(this).val(cutVal);
  syncFields($(this));
})

$('.reason').on('input', function () {
  syncFields($(this));
})


