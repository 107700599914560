import "jquery-validation";

$('#contactForm').validate({
  errorElement: 'span',
  rules: {
    name: "required",
    email: {
      required: true,
      email: true
    },
    password: {
      required: true,
      minlength: 6
  },
  }
  });

$('#loginForm').validate({
  errorElement: 'span',
  rules: {
    name: "required",
    email: {
      required: true,
      email: true
    },
    password: {
      required: true,
      minlength: 6
    },
  }
});
