
const textFields = $('textarea');

textFields.each(function() {
  $(this).keydown(function(e) {
    if(e.keyCode === 9) { // tab was pressed
        // get caret position/selection
        var start = this.selectionStart;
            end = this.selectionEnd;
  
        var $this = $(this);
        $this.val($this.val().substring(0, start)
                    + "\t"
                    + $this.val().substring(end));
        this.selectionStart = this.selectionEnd = start + 1;
        return false;
    }
  });
})
