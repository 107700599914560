import CodeMirror from 'codemirror/lib/codemirror';
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/ruby/ruby.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/lib/codemirror.css";

const codeFields = () => {
  if (!!document.getElementsByClassName('js-code-ruby').length) {
    return document.getElementsByClassName('js-code-ruby');
  }
  return document.getElementsByClassName('js-code-js');
}
const fieldsCollection = codeFields();
const answerCollection = document.getElementsByClassName('js-output');

const renderField = (collection, onlyRead = false) => {

  for (let i = 0; i < collection.length; i++) {
    const item = collection[i];
    const isRuby = item.classList.contains('js-code-ruby');

    CodeMirror.fromTextArea(item, {
      tabSize: 2,
      matchBrackets: true,
      autoCloseBrackets: true,
      mode: isRuby ? 'text/x-ruby' : 'javascript',
      lineNumbers: true,
      readOnly: onlyRead
    });
  }
}

renderField(fieldsCollection);

renderField(answerCollection, 'nocursor');
