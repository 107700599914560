import "select2";
import "select2/dist/css/select2.css";

$('.js-select').select2({
  placeholder: "Select course",
});

$('.js-select').on('change', function () {
  $(this).closest('form').submit();
});
